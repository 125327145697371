@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

@font-face {
  font-family: proxima;
  src: url(./proxima/ProximaNova-Regular.otf);
}
* {
  outline: none;
  font-family: proxima;
}

:root {
  --yellow--: #fcd51f;
  --darkGray--: #343d47;
  --Graddish--: #1a1d20;
}
.donation__Btn {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: none;
  background: #fff;
  color: var(--darkGray--);
  border-right: 0.5rem solid var(--yellow--);
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 1.2rem 1.6rem;
  font-weight: 400;
  font-size: 1rem !important;
  cursor: pointer;
  transition: 0.5s;
}

.donation__Btn:hover {
  background: var(--yellow--);
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  text-decoration: none !important;
  color: var(--darkGray--) !important;
}

html {
  scroll-behavior: smooth;
  transition: 0.5s;
}
body {
  /* font-family: "Lato", sans-serif; */
  box-sizing: border-box;
}
#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: var(--yellow--) !important;
  color: var(--darkGray--);
  cursor: pointer;
  padding: 0rem;
  padding: 0.8rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 50%;
}
#myBtn .fa {
  font-size: 1.5rem !important;
}
#myBtn:hover {
  background-color: var(--darkGray--) !important;
  color: var(--yellow--);
}

.app {
  overflow: hidden;
  position: relative;
  height: 85vh;
}
@media (max-width: 767px) {
  .app {
    height: 65vh;
  }
}
