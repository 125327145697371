@import url(https://fonts.googleapis.com/css?family=Itim:regular);
:root {
  --yellow--: #fcd51f;
  --darkGray--: #343d47;
  /* --darkYellow--: #f8cb00; */
}
.aboutBtn {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: none;
  background: #fff;
  color: var(--darkGray--);
  border-right: 0.5rem solid var(--yellow--);
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 1.2rem 1.6rem;
  font-weight: 400;
  font-size: 1rem !important;
  cursor: pointer;
  transition: 0.5s;
}

.aboutBtn:hover {
  background: var(--yellow--);
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  text-decoration: none !important;
}
.aboutusContain {
  background: var(--yellow--);
}
.about1 {
  color: var(--darkGray--);
}
.about2 {
  color: #fff;
}
.aboutBtn {
  border-right: 0.5rem solid var(--darkGray--) !important;
}
.aboutBtn:hover {
  background: var(--darkGray--) !important;
  color: var(--yellow--) !important;
}
.aboutImageSection {
  background-image: url(../../img/about2.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.aboutImg {
  width: 90%;
}

.staffing__name {
  font-family: "Itim", cursive;
}

@media (max-width: 767px) {
  .aboutImg {
    width: 100%;
  }
  .linebreak{
    background-color: var(--darkGray--);
    height: 1px;
    width: 100%;
    margin: 2rem;
  }
}
