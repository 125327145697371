:root {
  --yellow--: #fcd51f;
  --darkGray--: #343d47;
  --Graddish--: #1a1d20;
  --textColor--: rgb(204, 203, 203);
}
.footer {
  background-color: var(--Graddish--);
  color: #fff;
  padding-top: 4rem;
  padding-bottom: 1rem;
}
.footer p {
  color: var(--textColor--) !important ;
}
.footer__link {
  color: var(--textColor--);
}
.footer__link:hover {
  color: var(--yellow--);
  text-decoration: none;
}
.footer .footerLogo {
  width: 200px;
  margin-bottom: -2rem;
  margin-top: -2rem;
  margin-left: -2rem;
}
.footer h5 {
  margin-top: 2rem;
}
.footer__icons .fa {
  font-size: 1.5rem !important;
  background-color: transparent !important;
  color: var(--textColor--) !important;
}
.footer__icons .fa:hover {
  color: var(--yellow--) !important;
  transition: 0.5s cubic-bezier(1, 0, 0, 1);
  cursor: pointer;
}
.footer .fa {
  color: var(--yellow--);
  font-size: 1.2rem;
  padding: 0.2rem 0.5rem;
  padding-left: 0;
  margin-right: 0.4rem;
  border-radius: 50%;
}
.contact .address {
  display: flex;
  align-items: center;
}
.contact .footerCont {
  border-bottom: 0.1rem solid rgb(83, 83, 83) !important;
  margin-bottom: 1.2rem;
}
.contact .address strong {
  color: var(--yellow--);
}
.gallery .galleryImage {
  border: 0.1rem solid var(--yellow--);
  width: 100%;
  height: 90px;
  object-position: center !important;
  object-fit: cover !important;
}
.gallery .imageContain {
  overflow: hidden;
}
.gallery .galleryImage:hover {
  transform: scale(1.1);
  cursor: pointer;
  transition: 0.5s cubic-bezier(1, 0, 0, 1);
}
.footer .copyright {
  color: rgb(83, 83, 83) !important;
  border-top: 0.1rem solid rgb(83, 83, 83) !important;
}
