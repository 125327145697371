:root {
  --yellow--: #fcd51f;
  --darkGray--: #343d47;
}


.slider-wrapper {
  height: 80vh;
}

.slider-content {
  padding: 5rem;
}
.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, var(--darkGray--));
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  display: none;
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 5rem;
  margin-bottom: 2rem;
  margin-left: 0;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: var(--yellow--);
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 2rem;
  max-width: 640px;
}

@media (max-height: 500px) {
  .slider-wrapper {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  .slider-wrapper {
    height: calc(80vh - 75px);
  }
  .slider-content {
    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .slider-content .inner h1 {
    margin: 0;
    margin-top: 4rem;
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}
